import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LightAuthService } from 'src/app/core/services/light-auth/light-auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { UserProfile } from 'src/app/core/types/userProfile';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.less']
})
export class AuthCallbackComponent implements OnInit {
   profile: UserProfile = {} as UserProfile;
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    private authService: LightAuthService,
    private profileService: ProfileService
  ) {
    this.profileService.profile$.subscribe((profile) => {
      this.profile = profile;
    });
  }



  ngOnInit() {
    this.authService.processCallBack(this.router.url);
    this.renderer.listen('window', 'message', (event) => {
      // this.receiveMessage(event);
      const authSuccess = event.data === 'auth_success' ? true : false;
      if (authSuccess) {
        this.router.navigate(['/landing'], { queryParams: {}, queryParamsHandling: 'merge', state: { profile: this.profile } });
      }
    })
  }
}
