export type PolicyDetails = {
    beginDt: Date;
    enrollmentEffectiveDt: Date;
    programTypeCd: string;
    recordAddTs: Date;
    statusCd: string;
    recordAddUserId: string;
    policy: Policy;
    _links: any;
}

export type Policy = {
    channelCd: string;
    expirationDt: Date;
    producerIdSet: string;
    rateStateAbbrNm: string;
    recordAddTs: Date;
    recordAddUserId: string;
    policyNb: number;
    renewalDt: Date;
    termPeriodCd: string;
    policyType: string;
    policyPartneridXrefs: string[];
    _links: {
        programs: {
            href: string;
        }
    };
}

export enum PolicyType {
    HOMEOWNER = "homeowner",
    TENANT = "tenant",
    CONDO = "condo"
}