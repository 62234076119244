import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';
import { InventoryInfo } from '../../types/inventory';
import { InventoryService } from '../inventory/inventory.service';
import { DeviceDetails } from '../../types/http/deviceDetails';
import { DeviceOrder } from '../../types/http/deviceOrder';
import { DeviceRecommendation, DeviceRecommendationPayload } from '../../types/http/deviceRecommendation';
import { NewDevice } from '../../types/http/newDevice';
import { SortParameters } from '../../types/http/sortParameters';
import { UpdatedDeviceType } from '../../types/http/updatedDeviceType';
import { UUID } from '../../types/uuid';
import { ApiService } from '../api/api.service';
import { DeviceVendorDetails } from '../../types/http/vendorDetails';
import { DeviceTypeList } from '../../types/http/deviceTypeList';
import { shouldUpdateDate } from '../../date-helpers';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private api: ApiService, private inventoryService: InventoryService) { }

  async getAllDeviceTypes(sortParams?: SortParameters): Promise<DeviceTypeList> {
    return await lastValueFrom(this.api.apigeeGet('device', { params: sortParams })).catch((err) => { throw err });
  }

  async createDeviceType(newDeviceDetails: NewDevice): Promise<DeviceDetails> {
    return await lastValueFrom(this.api.apigeePost('device', newDeviceDetails)).catch((err) => { throw err });
  }

  async updateDeviceDetails(deviceId: UUID, updateDeviceDetails: UpdatedDeviceType): Promise<DeviceDetails> {
    return await lastValueFrom(this.api.apigeePatch(`device/${deviceId}`, updateDeviceDetails)).catch((err) => { throw err });
  }

  async getVendorDetails(deviceId: UUID): Promise<DeviceVendorDetails> {
    return await lastValueFrom(this.api.apigeeGet(`device/${deviceId}/vendor`)).catch((err) => { throw err });
  }

  async placeDeviceOrder(deviceOrder: DeviceOrder): Promise<{ 'message': string }> {
    return await lastValueFrom(this.api.apigeePost('device/order', deviceOrder)).catch((err) => { throw err });
  }

  async getDeviceRecommendations(policyNumber: string, params: DeviceRecommendationPayload): Promise<DeviceRecommendation[]> {
    return await lastValueFrom(this.api.apigeeGet(`policies/${policyNumber}/devices`, { params })).catch((err) => { throw err });
  }

  async getInventory(): Promise<InventoryInfo[]> {
    const inventoryData: InventoryInfo[] = [];
    let lastUpdatedDate: string = '';
    await this.inventoryService.getInventoryDetails({ sort: 'asc' }).then(async (inventoryResponse: any) => {
      const inventory: any = inventoryResponse._embedded.inventory;
      for (let item of inventory) {
        let inventoryInfo: any = {};
        inventoryInfo.availabilityCount = {};
        inventoryInfo.availabilityCount.originalValue = inventoryInfo.availabilityCount.newValue = item.availabilityCount;
        inventoryInfo.recordUpdateTs = item.recordUpdateTs;
        if (shouldUpdateDate(lastUpdatedDate, item.recordUpdateTs)) {
          lastUpdatedDate = item.recordUpdateTs;
        }

        const deviceTypeURL = item._links.deviceType.href;
        inventoryInfo.inventoryId = deviceTypeURL.substring(deviceTypeURL.indexOf('inventory/') + 'inventory/'.length, deviceTypeURL.indexOf('/deviceType'));

        await this.getInventoryDeviceType(inventoryInfo.inventoryId).then(async (response: any) => {
          if (shouldUpdateDate(lastUpdatedDate, response.recordUpdateTs)) {
            lastUpdatedDate = response.recordUpdateTs;
          }
          inventoryData.push(new InventoryInfo({ ...inventoryInfo, ...response }));
        });
      }
    });
    for (let data of inventoryData) {
      data.recordUpdateTs = lastUpdatedDate;
    }
    return inventoryData;
  }

  async getInventoryDeviceType(inventoryId: string): Promise<any> {
    let inventoryInfo: any = {};
    await this.inventoryService.getDeviceTypes(inventoryId).then(async (deviceResponse: any) => {
      inventoryInfo.deviceType = deviceResponse.deviceName;
      inventoryInfo.cost = {};
      inventoryInfo.cost.originalValue = inventoryInfo.cost.newValue = deviceResponse.cost;
      inventoryInfo.discountAmount = deviceResponse.discountAmount;
      inventoryInfo.deviceTypeURL = deviceResponse._links.deviceType.href;
      inventoryInfo.recordUpdateTs = deviceResponse.recordUpdateTs;

      const vendorURL = deviceResponse._links.vendor.href;
      inventoryInfo.deviceId = vendorURL.substring(vendorURL.indexOf('device/') + 'device/'.length, vendorURL.indexOf('/vendor'));

      await this.getDeviceVendorInfo(inventoryInfo.deviceId).then(async (response: InventoryInfo) => {
        inventoryInfo.vendor = response.vendor;
      });
    });
    return inventoryInfo;
  }

  async getDeviceVendorInfo(deviceId: string): Promise<any> {
    const inventoryInfo: any = {};
    await this.getVendorDetails(deviceId).then(async (vendorResponse: any) => {
      inventoryInfo.vendor = vendorResponse.vendorName;
    });
    return inventoryInfo;
  }
}
