import { Injectable, Renderer2 } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { ConfigLoaderService } from '../config-loader/config-loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserPrivilege } from '../../constants';
import { PolicyType } from '../../types/policy-info';

@Injectable({
  providedIn: 'root'
})
export class LightAuthService {
  // for calling identity
  apigeeHostUrl: string;
  // baseUrl: string;
  clientId: string;
  nounce: string;
  redirectUrl: string;
  responseType: string;
  scope: string;
  realm: string;
  authMethod: string;
  ttl: number;
  jwtDecode = jwtDecode;
  id_token: string;
  agreements: any;


  // for accessing services
  accessToken: string = '';
  decodedIdentityToken: any = {};
  private renderer!: Renderer2;


  constructor(private config: ConfigLoaderService,
    private router: Router,
    private route: ActivatedRoute,) {
    this.apigeeHostUrl = this.config.app.secrets.authorize.oidc.authority;
    this.clientId = this.config.app.secrets.smartHomeMarket.clientId;
    this.nounce = 'myNonce';
    this.redirectUrl = window.location.origin + "/auth-callback";
    this.responseType = 'token id_token';
    this.scope = 'openid';
    this.realm = 'unidentified';
    this.authMethod = 'customer-search';
    this.ttl = 1200;
    this.id_token = ''
    this.agreements = []
  }

  setRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }

  buildAuthCustomerSearchUrl(lastName: string, policyNumber: string, zip: string): string {
    return `${this.apigeeHostUrl}?client_id=${this.clientId}&nonce=${this.nounce}&redirect_uri=${this.redirectUrl}&response_type=${this.responseType}&scope=${this.scope}&realm=${this.realm}&auth_method=${this.authMethod}&auth_id_lastName=${encodeURIComponent(lastName)}&auth_id_postalCode=${encodeURIComponent(zip)}&auth_id_agreementNumber=${encodeURIComponent(policyNumber)}&state=request-type-create-session--smart-home`
  }
  memberAuthUrl(): string {
    return `${this.apigeeHostUrl}?client_id=${this.clientId}&nonce=${this.nounce}&redirect_uri=${this.redirectUrl}&response_type=${this.responseType}&scope=${this.scope}&realm=member&auth_method=ping&state=request-type-create-session--smart-home`
  }


  processCallBack(urlFragment: string): any {
    console.log("urlFragment", urlFragment)
    const authType = window.sessionStorage.getItem('tokenDetails') ? 'refresh' : 'login';
    if (urlFragment.includes('&') && urlFragment.includes('=')) {
      // Convert to list of parameters to easily parse
      const tokenFragment = urlFragment.split('#')[1];
      const parameterString = tokenFragment.split('&').map(pair => {
        const [name, value] = pair.split('=');
        window.sessionStorage.setItem(name, value); // store in session
        return {
          name: decodeURIComponent(name),
          value: decodeURIComponent(value)
        };
      });
      parameterString.forEach((paramObj: any) => {
        if (paramObj.name == 'id_token') {
          this.id_token = paramObj.value
          return;
        }
      })
      this.agreements = this.getAgreementsFromIdToken(this.id_token);
      // if (this.agreements?.length > 1) {
      //   this.router.navigateByUrl('/error', { state: { errorEvent: 'multipleAggrements' } });
      // }
      sessionStorage.setItem('policyNumber', this.agreements[0]?.agreement_number)
      sessionStorage.setItem('policyType', this.agreements[0]?.product_type)
      // Determine if success of failure
      if (parameterString.some(param => param.name === 'access_token')) {
        // now calculate the time in ms token expires
        window.sessionStorage.setItem('expires_at', (Date.now() + (parseInt(parameterString.find(param => param.name === 'expires_in')!.value) * 1000)).toString()); // store in session

        // pull out details
        this.accessToken = parameterString.find(param => param.name === 'access_token')!.value;

        sessionStorage.setItem('access_token', this.accessToken);
        parent.postMessage(`auth_success`, window.location.origin);
      } {
        // Failure
        parent.postMessage(`auth_failed`, window.location.origin);
      }

      return false;
    }
  }

  getAgreementsFromIdToken(idToken: string) {
    let jwt_object = JSON.parse(JSON.stringify(this.getDecodedAccessToken(idToken)));
    return jwt_object?.agreements?.filter((agreement: any) => {
      const ptype = this.matchStringToEnum(agreement?.product_type.toLowerCase());
      if (ptype) {
        agreement.product_type = ptype;
        return true;
      }
      return false;
     });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    }
    catch (Error) {
      return null;
    }
  }



  matchStringToEnum(productType: string): PolicyType | undefined {
    if (productType.includes(PolicyType.HOMEOWNER.toLowerCase())) {
      return PolicyType.HOMEOWNER
    }
    if (productType.includes(PolicyType.CONDO.toLowerCase())) {
      return PolicyType.CONDO
    }
    if (productType.includes(PolicyType.TENANT.toLowerCase())) {
      return PolicyType.TENANT
    }
    return undefined;
  }

}
